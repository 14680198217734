import React from "react"
import { Statistic } from "antd"
import { useSelector } from "react-redux"
import { addDays } from "date-fns"
import { Link } from "gatsby"
import { format } from "@/utils"
import Heading from "@/components/Heading"
import StakeTotal from "@/components/StakeTotal"
import { SVGLink } from "@/svg"
// import * as style from "./style.module.scss"

const TadaDistribution = () => {
  const pools = useSelector((state) => state.settings.pools)
  const history = useSelector((state) => state.settings.history)
  const rate = pools?.nextRate

  return (
    <div className="tada__block">
      <Heading id="distribution">
        <strong>TADA Stake</strong>, ADA staking & TADA ISPO
      </Heading>
      <p>
        TADATek provides Cardano staking services on high-performance servers in
        the Cloudflare cloud. High availability, high uptime, low latency and
        automated infrastructure deployment allow for the highest possible ROI.
      </p>
      <p className="mb-5">
        Stake ADA in TADA pools and get <strong>~5% ROI</strong> with extra
        rewards each epoch 1 <strong className="tada__ticker">TADA</strong> per{" "}
        {rate / 1000000} <strong className="tada__ticker">ADA</strong> staked.
      </p>
      <div className="mt-4 pt-3 mb-5 pb-3">
        <span className="d-inline-block me-3 mb-2">
          <a
            className="tada__btn tada__btn--round"
            href="https://stake.tadatek.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="me-2">TADAStake App</span>
            <span className="tada__icon">
              <SVGLink />
            </span>
          </a>
        </span>
        <span className="d-inline-block me-3 mb-2">
          <a
            className="tada__btn tada__btn--round"
            href="https://app.tadatek.io/#/stake"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="me-2">Delegate</span>
            <span className="tada__icon">
              <SVGLink />
            </span>
          </a>
        </span>
        {/* <span className="d-inline-block me-3 mb-2">
          <Link className="tada__btn tada__btn--round" to="/stake/pools/">
            Stake Pools
          </Link>
        </span>
        <span className="d-inline-block me-3 mb-2">
          <Link className="tada__btn tada__btn--round" to="/stake/calculator/">
            Calculator
          </Link>
        </span> */}
        <span className="d-inline-block me-3 mb-2">
          <Link
            className="tada__btn tada__btn--round"
            to="/wiki/stake-delegators/"
          >
            Wiki
          </Link>
        </span>
      </div>
      <h5>
        <strong>TADA pools information</strong>
      </h5>
      <div className="row mb-4">
        <div className="col-12 col-sm-4 mb-4">
          <div className="tada__left tada__left--dark">
            <div className="tada__card__value">
              {format(pools?.totalLiveStake / 1000000 || 0)}{" "}
              <span className="tada__ticker">ADA</span>
            </div>
            <div>Live Stake</div>
          </div>
        </div>
        <div className="col-12 col-sm-4 mb-4">
          <div className="tada__left tada__left--dark">
            <div className="tada__card__value">
              {format(pools?.totalRewards / 1000000 || 0)}{" "}
              <span className="tada__ticker">ADA</span>
            </div>
            <div>Total ADA Rewards</div>
          </div>
        </div>
        <div className="col-12 col-sm-4 mb-4">
          <div className="tada__left tada__left--dark">
            <div className="tada__card__value">
              {pools?.pools?.reduce(
                (accum, pool) => accum + parseInt(pool.delegators),
                0
              ) || 0}
              {" / "}
              {pools?.pools?.length || 0}
            </div>
            <div>Delegators / Pools</div>
          </div>
        </div>
      </div>
      <h5>
        <strong>
          {history?.lastSynced &&
            `TADA Epoch ${history?.lastSynced?.epoch} Payouts (For Epoch ${
              history?.lastSynced?.epoch - 2
            })`}
          {!history?.lastSynced && `Current Epoch Payouts`}
        </strong>
      </h5>
      <div className="row mb-4">
        <div className="col-12 col-sm-4 mb-4">
          <div className="tada__left tada__left--dark">
            <div className="tada__card__value">
              {format(history?.lastSynced?.accrued || 0)}
              <span className="tada__ticker">TADA</span>
            </div>
            <div>TADA Payouts</div>
          </div>
        </div>
        <div className="col-12 col-sm-4 mb-4">
          <div className="tada__left tada__left--dark">
            <div className="tada__card__value">
              {format(history?.lastSynced?.max || 0)}{" "}
              <span className="tada__ticker">TADA</span>
            </div>
            <div>TADA Epoch Limit</div>
          </div>
        </div>
        <div className="col-12 col-sm-4 mb-4">
          <div className="tada__left tada__left--dark">
            <div className="tada__card__value">
              <span>{format(history?.lastSynced?.rate / 1000000 || 0)}</span>
              <span className="tada__card__amount__small me-2">
                .
                {(history?.lastSynced?.rate / 1000000 || 0)
                  .toString()
                  .split(".")[1] || "000000"}
              </span>
              <span className="tada__ticker tada__ticker__lg">ADA</span>
            </div>
            <div>Rewards Rate</div>
          </div>
        </div>
        <div className="col-12 col-sm-4 mb-4">
          <div className="tada__left tada__left--dark">
            <div className="tada__card__value">
              <span className="me-2">
                {format(history?.lastSynced?.snapshot / 1000000 || 0)}
              </span>
              <span className="tada__ticker">ADA</span>
            </div>
            <div>Active Stake Snapshot</div>
          </div>
        </div>
        <div className="col-12 col-sm-4 mb-4">
          <div className="tada__left tada__left--dark">
            <div className="tada__card__value">
              <Statistic.Countdown
                className="tada__count__inline"
                value={addDays(
                  new Date(history?.currentEpoch?.startedAt || null),
                  5
                )}
                format="D[d] HH[h] mm[m] ss[s]"
              />
            </div>
            <div>Next Rewards</div>
          </div>
        </div>
      </div>
      <h5>
        <strong>TADA distribution schedule</strong>
      </h5>
      <div>
        <StakeTotal />
      </div>
    </div>
  )
}

export default TadaDistribution
